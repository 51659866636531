import { isEmpty } from 'lodash-es';
import InquiryStatus from '../constants/InquiryStatus';
import RouteNames, { slimLayoutRoutesArray, wideLayoutRoutesArray } from '../constants/RouteNames';

export default {
  computeIsWideLayout,
  computeIsUnitPreviewMode,
  isUnitOrInquiryExactRoute,
  getCurrentRouteDefinition,
  conditionallyAddRouteTransition,
};

function getCurrentRouteDefinition(routeName) {
  const isHomePage = window.location?.pathname.lastIndexOf('/') === 0;
  const isNestedRoute = routeName && /\./gi?.test(routeName);
  const isNewLayoutRoute = isHomePage || (isHomePage && !isNestedRoute) || (routeName && /^(unit|inquiry.preview)$/.test(routeName));

  return {
    isHomePage,
    isNestedRoute,
    isNewLayoutRoute,
  };
}

/* This function is a bit confusing due to how we treat our async data and routes,
 * We show wide layout for pending inquiries, iquiries preview and unit landing
 * We decide on the background only after we have the routeName - therefore the function isn't fully determinisic
 */
function computeIsWideLayout({ routeName = '', $store, isMobile }) {
  const bgColors = {
    PRIMARY: 'bg-color-primary',
    WHITE: 'bg-white',
    TRANSPARENT: 'bg-transparent',
  };
  let bgColor = bgColors.TRANSPARENT;
  let isWideLayout = false;
  let isRouteWithHeaderPicture;

  const notFoundRoute = routeName === RouteNames.NOT_FOUND;

  if (routeName) {
    const { hasError } = $store.getters;
    if (slimLayoutRoutesArray.includes(routeName) || hasError || notFoundRoute) {
      bgColor = bgColors.WHITE;
      isWideLayout = false;
    } else if (wideLayoutRoutesArray.includes(routeName)) {
      const { inquiry } = $store.getters;
      const isInquiryPending = inquiry?.status === InquiryStatus.PENDING;
      const isShowingProfileNotPublishListing = !$store.getters.isShowingProfilePublishListing;
      const shouldAppendLayout = isEmpty(inquiry) || (isInquiryPending && isShowingProfileNotPublishListing) || computeIsUnitPreviewMode(routeName);
      const isMultiUnit = $store.getters.isMultiUnitInquiry;
      isWideLayout = !isMultiUnit && shouldAppendLayout;
      bgColor = !isMobile?.value && !isMultiUnit && shouldAppendLayout ? bgColors.PRIMARY : bgColors.WHITE;
      isRouteWithHeaderPicture = routeName === RouteNames.INQUIRY_HOME || shouldAppendLayout;
    }
  }

  return {
    isWideLayout,
    isRouteWithHeaderPicture,
    bgColor,
  };
}

function computeIsUnitPreviewMode(routeName) {
  return routeName && /^(inquiry.preview)$/.test(routeName);
}

function isUnitOrInquiryExactRoute(routeName) {
  return /^(inquiry|unit)$/.test(routeName);
}

function conditionallyAddRouteTransition(store, isMobile) {
  return (to, from) => {
    const routeDefinition = computeIsWideLayout({ $store: store, routeName: to.name, isMobile });
    const prevRouteDefinition = computeIsWideLayout({ $store: store, routeName: from.name, isMobile });
    const shouldTransition = !!isMobile?.value || !from.name || (!routeDefinition.isWideLayout && !prevRouteDefinition.isWideLayout);
    to.meta.transition = undefined;
    if (shouldTransition) {
      to.meta.transition = {
        name: 'fade',
        mode: 'out-in',
      };
    }
  };
}
